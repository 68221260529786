import React from "react";
import SingleDropdown from "./SingleDropdown";

const HemstadningQ = ({ linkoping }) => {
	return (
		<div class="bg-gray-50">
			<div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
				<div class="max-w-3xl mx-auto">
					<h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
						Det här ingår i hemstädningen
					</h2>
					<p class="mt-5 max-w-prose mx-auto text-xl text-gray-500 ta-center ">
						{linkoping ? `Se vad vårt grundpaket innehåller. Självklart går det att välja till fler tjänster om så önskas. Vi erbjuder bästa möjliga städning med utbildade städare och miljövänliga material. ` : `Se vad vårt grundpaket innehåller. Det går självklart att lägga till
						de tjänster du önskar. Med utbildade städare och miljövänliga
						städmaterial ser vi till att erbjuda bästa möjliga städning.`}
					</p>
					<SingleDropdown
						text="Allmänt / Alla utrymmen"
						items={[
							"Dammtorkar alla plana ytor (som städaren når upp till, högst 180cm höjd)",
							"Dammsuger golv och mattor.",
							"Moppar golv (om golvmaterialet tål fukt).",
							"Dammtorkar golvlister.",
							"Våttorkar dörrhandtag.",
							"Våttorkar fönsterbrädor.",
							"Avlägsnar fläckar från strömbrytare.",
							"Putsar speglar och andra glasytor (ej fönster).",
							"Tar ut soporna (om du har visat vart de ska, eller ordnat tillgång till soprummet).",
						]}
					/>
					<SingleDropdown
						text="Kök (inkl. allmänna moment)"
						items={[
							"Rengör utvändigt skåp, hyllor och lådor.",
							"Rengör utvändigt kyl, frys och diskmaskin.",
							"Rengör ut- och invändigt mikrovågsugn.",
							"Rengör utvändigt spis, ugn och köksfläkt.",
							"Rengör köksbänken.",
							"Rengör kakel/stänkskydd ovanför diskbänk.",
							"Putsar diskho, blandare, propp och sil.",
							"Moppar golv (om golvmaterialet tål fukt)",
						]}
					/>
					<SingleDropdown
						text="Toalett / Badrum (inkl. allmänna moment)"
						items={[
							"Rengör ut- och invändigt toalettstolen.",
							"Rengör kran och handfat.",
							"Rengör hela duschen alt. badkaret.",
							"Putsar blandare och duschmunstycke.",
							"Torkar utvändigt badrumsskåp.",
							"Putsar speglar och andra glasytor (ej fönster).",
							"Moppar golv.",
							"Rengör kran och handfat.",
						]}
					/>
					<SingleDropdown
						text="Tjänster som kan bokas separat"
						items={[
							"Invändig rengöring av tömt kylskåp (30 min)",
							"Invändig rengöring av ugnen (30 min)",
							"Invändig rengöring av sopsorteringsskåp (30min).",
							"Rengöring av möbler (15min/soffa)",
						]}
					/>
				</div>
			</div>
		</div>
	);
};

export default HemstadningQ;
