import React, { useEffect, useState } from "react";
import { Seo } from "../../../components/seo";
import Layout from "../../../hoc/Layout/Layout";
import Booking from "../../../components/Booking/Booking";
import GradeSection from "../../../components/GradeSection/GradeSection";
import AdvantagesSection from "../../../components/AdvantagesSection/AdvantagesSection";
import Instructions from "../../../components/Instructions/Instructions";
import Faq from "../../../components/FAQ/FAQHemstadning";
import HemstadningQ from "../../../components/FAQ/HemstadningQ";
import { SERVICE_TYPES } from "../../../enums/serviceTypes";
import { navigate } from "gatsby";

// markup
const IndexPage = () => {
	const [bookingActive, setBookingActive] = useState(false);
	const [serviceChosen, setServiceChosen] = useState(false);
	const [ableToGoBack, setAbleToGoBack] = useState(false);
	const [finishBookingStep, setFinishBookingStep] = useState();

	useEffect(() => {
		const city = localStorage.getItem('city');
		if (city !== 'linkoping') {
			navigate('/tjanster/hemstadning')
		}
	}, []);

	return (
		<Layout
			bookingActive={bookingActive}
			serviceChosen={serviceChosen}
			ableToGoBack={ableToGoBack}
			finishBookingStep={finishBookingStep}
			setFinishBookingStep={setFinishBookingStep}
		>
			<Seo title="Hemstädning Jönköping | Miljövänlig Hemstädning - Hemstäd DreamClean" />
			<Booking
				setBookingActive={setBookingActive}
				choosenService={SERVICE_TYPES.Hemstädning}
				firstStep={2}
				setServiceChosen={setServiceChosen}
				setAbleToGoBack={setAbleToGoBack}
				finishBookingStep={finishBookingStep}
				setFinishBookingStep={setFinishBookingStep}
			/>
			{!bookingActive && (
				<>
					<AdvantagesSection
						type={SERVICE_TYPES.Hemstädning}
						text1={{
							heading: "Socialt ansvarstagande hemstädningsföretag",
							description:
								"Vi har kollektivavtal med Almega och våra anställda får självklart betald semester, pension, friskvårdsbidrag och andra förmåner.",
						}}
						text2={{
							heading: "Inga bindningstider",
							description:
								"Våra kunder stannar för att tjänsten är bra, inte på grund av långa bindningstider.  Pausa ditt abonnemang när du vill, du är alltid välkommen tillbaka.",
						}}
						text3={{
							heading: "Alltid samma städare",
							description:
								"En av våra utbildade städproffs väljs noggrant ut och städar hos dig samma tid och samma veckodag. Detta för att lära känna ditt hem och utföra högsta kvalitet av städning. ",
						}}
						text4={{
							heading: "Miljömärkta medel ingår",
							description:
								"Vid start får du ett städpaket med miljömärkta städmedel. Materialet budas hem till dig och fylls på när de tar slut. Vi använder din egen dammsugare.",
						}}
					/>
					<HemstadningQ linkoping />
					<Instructions
						bg
						linkopingHeader={"Vi gör det enkelt att boka städning i Linköping"}
						linkopingDescription={"Det ska vara enkelt att skaffa städhjälp. Vi hjälper dig komma igång med städningen i Linköping, snabbt och enkelt. "}
						text1="Ange ditt postnummer och hur stor din bostad är."
						text2="Se pris och boka ett kostnadsfritt startmöte den tid som passar dig där vi går igenom städupplägg tillsammans."
						text3="Din städare kommer till dig vid önskad tidpunkt och du får njuta av ett rent och snyggt hem!"
					/>
					<Faq linkoping />
					<GradeSection />
				</>
			)}
		</Layout>
	);
};

export default IndexPage;
