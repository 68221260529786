import React from "react";
import SingleDropdown from "./SingleDropdown";

const FAQ = ({ linkoping }) => {
	return (
		<div class="bg-gray-50">
			<div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
				{/* <h2 class="text-center font-semibold tracking-wider text-green-600 uppercase">
          Allt, och lite till.
        </h2> */}
				<div class="max-w-3xl mx-auto">
					<h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
						Vanliga frågor och svar
					</h2>
					<SingleDropdown
						text="Hur kommer jag igång med löpande hemstädning?						"
						description="Vi börjar alltid med ett kostnadsfritt startmöte som sker hemma hos dig eller digitalt via videosamtal, du väljer!  Boka ett kostnadsfritt startmöte här på vår hemsida eller kontakta vår kundservice via e-mail eller telefon. "
					/>
					<SingleDropdown
						text="Vad behöver jag ha hemma inför en hemstädning?"
						description="I abonnemanget för hemstädning ingår miljömärkta städmedel samt dukar, moppar och moppstativ. Materialet budas hem till dig och fylls på när de tar slut. Det enda du behöver ha hemma inför städningen är en dammsugare. "
					/>
					<SingleDropdown
						text="Hur vet jag hur lång tid städningen tar?"
						description="Efter startmötet får du en tidsuppskattning på hur lång tid vi tror att det kommer att ta för att få ditt hem skinande rent. Vi städar som minst 3 timmar varannan vecka. "
					/>
					<SingleDropdown
						text="Är det samma person som städar varje gång? "
						description="Vår ambition är att du ska få städning av samma städare varje gång. Om din ordinarie städare får förhinder kan en annan kollega från vårt serviceteam komma ut i stället. Du kan vara trygg i att alla våra städare genomgår samma grundliga utbildning i städning så att vi kan hålla kontinuiteten i din hemstädning. "
					/>
					<SingleDropdown
						text="Kommer ni samma veckodag och tid varje gång?"
						description="Vi bokar en fast veckodag och tid som vi håller oss till framöver för att göra det så smidigt som möjligt. Om det skulle ske en förändring i schemat t ex pga sjukdom hör din områdeschef av sig till dig om eventuellt byte av tid eller dag. Du kan även ladda ner appen “Mina städtjänster” och se kommande städningar.  "
					/>
					<SingleDropdown
						text="Kan jag vara hemma när ni städar?"
						description="Självklart går det bra, men det kan också vara bra att veta att du inte behöver vara det. "
					/>
					<SingleDropdown
						text="Kan mitt husdjur vara hemma under städningen? "
						description="Naturligtvis, så länge du ansvarar för att vi får arbetsro att göra ett gott arbete. Vi noterar i din kundprofil att du har husdjur så att din städare vet. "
					/>
					<SingleDropdown
						text="Hur hanterar ni mina nycklar?"
						description="När du bokat in din städning kan du välja att lämna in nyckel till oss innan städningen eller möta upp städaren och lämna nyckel hemma (om du inte vill vara hemma och öppna varje tillfälle). Vi kodar nyckeln så att den ej går att spåra till ditt hem. Dessa finns sen i gott förvar hos oss och används av städaren under städpasset hos dig. "
					/>
					<SingleDropdown
						text="Vad händer om något går sönder när ni städar hos mig? "
						description="Dreamclean har försäkringar som täcker eventuella skador på egendom som vår personal orsakar under ett städpass hemma hos dig. "
					/>
					<SingleDropdown
						text="Hur betalar jag för städningen? "
						description="Vi fakturerar för hela månadens städningar den 20:e varje månad. Det går även att välja autogiro som betalningsmetod för att slippa betala manuellt. "
					/>
					<SingleDropdown
						text="Hur funkar rut-avdraget? "
						description="Hushållsnära tjänster ger dig som konsument en skattereduktion på 50% av totalbeloppet, vilket innebär att du bara betalar halva priset. Avdraget görs direkt av oss."
						link
					/>
					<SingleDropdown
						text="Hur om- eller avbokar jag ett städtillfälle?"
						description={linkoping ? "För att avboka en bokning, vänligen kontakta vår kundservice på  linkoping@dreamclean.nu eller telefon 013 - 15 16 00" : "För att avboka en bokning, vänligen kontakta vår kundservice på e-post info@dreamclean.nu eller telefon 036 14 15 00. "}
					/>
				</div>
			</div>
		</div>
	);
};

export default FAQ;
